<template lang="pug">
  div
    .container-fluid#wrapper
      //-タイトル
      #title-container
        #title(v-if="isHeaderShow") 岩場を探す

      //-キーワード
      #keyword
        h6 キーワード
          b-form-input(placeholder="岩場名、岩名から探す" v-model="keyword")
      //-都道府県
      #prefectures
        h6 地域
        .button-check#prefectures-container
          .button-check-row
            input#RegionCd1(type="checkbox" name="" value="1" checked=false v-model="checkedRegiones")
            label(for="RegionCd1") 北海道
            input#RegionCd2(type="checkbox" name="" value="2" checked=false v-model="checkedRegiones")
            label(for="RegionCd2") 東北
            input#RegionCd3(type="checkbox" name="" value="3" checked=false v-model="checkedRegiones")
            label(for="RegionCd3") 関東甲信越
            input#RegionCd4(type="checkbox" name="" value="4" checked=false v-model="checkedRegiones")
            label(for="RegionCd4") 北陸・東海
          .button-check-row
            input#RegionCd5(type="checkbox" name="" value="5" checked=false v-model="checkedRegiones")
            label(for="RegionCd5") 近畿
            input#RegionCd6(type="checkbox" name="" value="6" checked=false v-model="checkedRegiones")
            label(for="RegionCd6") 中国
            input#RegionCd7(type="checkbox" name="" value="7" checked=false v-model="checkedRegiones")
            label(for="RegionCd7") 四国
            input#RegionCd8(type="checkbox" name="" value="8" checked=false v-model="checkedRegiones")
            label(for="RegionCd8") 九州・沖縄

      //-スタイル
      #climing-style
        h6 スタイル
        .button-check#climing-style-container
          .button-check-row
            input#climing-style1(type="checkbox" name="" value="bl" checked=false v-model="checkedClimingStyle")
            label(for="climing-style1") ボルダリング
            input#climing-style2(type="checkbox" name="" value="rc" checked=false v-model="checkedClimingStyle")
            label(for="climing-style2") ロープ

      //-詳細条件
      //-#detaile-conditions
        div
          b-card.mb-1(no-body="")
            b-card-header.p-1(header-tag="header" role="tab")
              b-button#search-conditions-detail(block="" href="#" v-b-toggle.accordion-1="" variant="Light") 
                span
                  |詳細条件
                  i.fas.fa-chevron-down
            b-collapse#accordion-1(visible=false accordion="my-accordion" role="tabpanel")
              b-card-body
                b-card-text
                  div 難易度
                  div
                    .button-check
                      .button-check-row
                        input#check-level(type="checkbox" name="" value="beginner" checked=false v-model="checkedLevel")
                        label(for="check-level") ビギナー向け（Level：～5.7）
                b-card-text
                  div キーワード
                  div
                    b-form-input(placeholder="岩場名、岩名から探す" v-model="keyword")

      //-検索ボタン
      #search
        b-button.button-blue(@click="searchRocky")
          span
            i.fas.fa-search
          |&nbsp;検索

      //TODO:for debug↓=====================================
      //span checkedClimingStyle: {{ checkedClimingStyle }}
      //span checkedRegiones: {{ checkedRegiones }}
      //span checkedLevel: {{ checkedLevel }}
      //span keyword: {{ keyword }}
      //TODO:for debug↑=====================================
</template>

<script>
export default {
  // データオブジェクト
  props: {
    isHeaderShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // デフォルト値
      // ログイン済みかどうか
      isLogin: this.$user.isLogin,
      checkedClimingStyle: [],
      checkedRegiones:[],
      checkedLevel: [],
      keyword: '',
      modal: false,
      isStyleShow: false,
      queryStr: ''
    }
  },
  //rocky/rocky_list画面の検索条件変更コンポーネントを使用した場合、
  //同一パス(パラメータのみ変更）となりコンポーネント再利用され
  //this.$router.pushしても再読み込みされない仕様である。
  //その為、watchでクエリストリングの変更をフックして、
  //$router.goを使用して強制的に再読み込みする。
  watch: {
    '$route' (to, from) {
      // ルートの変更の検知
      this.$router.go({path: to, force: true});
    }
  },
  // 算出プロパティ
  computed: {
  },
  // メソッド
  methods: {
    async searchRocky(){

      //検索条件のバリデーション
      if(await this.checkRegExp(this.keyword)){
        await this.asyncDialog('検索条件に使用できない文字が入力されています。')
        this.keyword = ''
        return
      }

      this.queryStr = '?style=' + this.checkedClimingStyle +
                    '&regiones=' + this.checkedRegiones +
                    //'&checkedLevel=' + this.checkedLevel +
                    '&keyword=' + this.keyword

      //検索条件コンポーネントのNavigationDuplicatedエラーを防ぐ為に無名関数を実装
      this.$router.push('/rocky/rocky_list' + this.queryStr, () => {}, () => {})
    },
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
    },
    //----------------------
    // 検索条件の入力チェック
    //----------------------
    async checkRegExp(targetStr){
      //正規表現パターン（API呼び出し時にエラーとなる文字）
      const reg = /[%']/

      if(reg.test(targetStr)) {
        return true
      }
      return false
    },
    //---------------
    // ダイアログ表示
    //---------------
    asyncDialog(msg) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk(msg, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
        .catch(err => {
          // An error occurred
        })
      })
    },
  },
  // コンポーネント
  components: {
    //MapModal
  },
  // インスタンス作成後
  created() {
    console.log('created')
  },
  // マウント後
  mounted() {
    console.log('mounted')
    this.$nextTick(function () {
      // ビュー全体がレンダリングされた後にのみ実行されるコード
      console.log('nextTick')
    })
  },
  // データ変更後
  updated() {
    console.log('updated')
  },
  // インスタンス破棄前
  beforeDestroy() {
    console.log('beforeDestroy')
  }
}
</script>

<style lang="stylus" scoped>
#wrapper
  display flex
  flex-direction column
  padding-top 10px
  position relative

//コンテナ群
#title
  text-align center
  font-size 1.8rem
  font-weight normal  
  color #0664a9
  margin 0.9rem 0

#map-button
  display flex
  width 95px
  height 35px
  margin 0 0 0 auto//右寄せ
  background #06188D
  border-radius 5px
  #map-img
    img
      width 35px
      height 35px  
  #map-title
    width 55px
    text-align right
    font-size 12px
    line-height 1.3
    color #EFEBEB

#keyword
  margin-top 2rem

  input
    max-width 260px
    margin-top 0.8rem
    margin-left 1rem

#climing-style
  margin-bottom 2rem

#climing-style-container
  padding-left 1rem

#prefectures
  margin-top 2rem //登攀スタイルを表示する場合は0にする
  margin-bottom 2rem

#prefectures-container
  padding-left 1rem

#detaile-conditions
  margin-bottom 3rem

#search
  margin-bottom 2rem

//ボタン化したチェックボックス
.button-check
  input[type="checkbox"] 
    display none
  label
    font-size 0.8rem
    display inline-block
    color #000
    background-color white
    border: 0.5px solid #3A5263
    border-radius 5px
    padding 2px 10px
  input[type="checkbox"]:checked 
    & + label 
      color white
      background-color #3A5263

#search-conditions-detail
  position relative
  i.fas.fa-chevron-down
    &:before
      content ""  
    &:after 
      font-family 'Font Awesome 5 Free'
      content "\f078"
      position absolute
      top 52%
      right 10px
      margin-top -10px
#accordion-1
  background-color rgba(0, 0, 0, 0.03);

.button-check-row
  label
    margin-top 0.3rem
    margin-right 1rem
    height 25px

#app
  h1,h2,h3,ha,h5,h6
    color #000

@media(min-width 1170px) 
  //pc表示
  #wrapper
    background-color white
    padding-left 10%
    padding-right 10%

</style>